body {
  margin: 0;
  padding: 0;
  font-family: $massText;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: $background-color;
}

html {
  scroll-behavior: smooth;
  // scroll-padding-top: 88px;
}

.container {
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: $max-width) {
  .container {
    max-width: 1600px;
    padding-right: 60px;
    padding-left: 60px;
  }
}
