.footer {
  padding: $global-spacing * 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icons {
    font-size: 30px;
    color: $light-grey;
    margin-bottom: $global-spacing * 2;
    &--icon {
      margin: 0 8px;
    }
  }
  &__copyright {
    color: $light-grey;
    font-size: 13px;
    &--text {
      margin: 0;
    }
  }
}
#footer {
  background-color: $navbar-color;
  box-shadow: 0 0 25px 1px black;
}

@media only screen and (min-width: $max-width) {
  .footer {
    padding: $global-spacing * 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

    &__icons {
      margin-bottom: 0;
      &--icon:hover {
        color: $brand-color;
      }
    }
  }
}
