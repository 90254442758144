#overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //background: rgba(0, 0, 0, 0.9);
  background-color: $navbar-color;
  // transition: ease 0.7s;
  display: flex;
  align-items: center;
  text-align: center;
}
#quotes {
  color: $brand-color;
  font-family: $heading;
  font-size: 30px;
  font-weight: $normal;
}

/* Loader icon */
.lds-ripple {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: $global-spacing * 2;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $brand-color;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
